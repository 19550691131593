<template>
  <div class="ingredient" :class="[activate && 'ingredient-selected']">
    <div class="ingredient-image">
      <img src="/images/emptys/empty-product.svg" alt="Ingrediente" />
    </div>
    <div class="ingredient-info">
      <div class="main-info">
        <h1>Conchas de Abanico sin Coral</h1>
        <p>Bolsa</p>
      </div>
      <div class="sub-info">
        <div class="ingredient-cant">
          <h2>Cantidad:</h2>
          <p>1</p>
        </div>
        <div class="ingredient-unit">
          <h2>Unidad:</h2>
          <p>1</p>
        </div>
      </div>
    </div>
    <div class="ingredient-check">
      <h2>Plaza Vea</h2>
      <input type="checkbox" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activate: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
